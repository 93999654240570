/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Thomson
VERSION:	1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/

@import 'variables.scss';

@import 'typography.scss';

@import 'mixins.scss';

@import 'buttons.scss';

@import 'common.scss';
@import 'templates/slider.scss';
@import 'templates/navigation.scss';
@import 'templates/portfolio.scss';
@import 'templates/service.scss';
@import 'templates/testimonial.scss';
@import 'templates/about.scss';
@import 'templates/contact.scss';
@import 'templates/blog.scss';
@import 'templates/footer.scss';