.main-nav {
	.header-top-socials{
		a{
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 14px;
			padding: 20px 6px;
		}
	}
}

.navbar-nav .nav-link {
	padding: 20px 15px!important;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px;
    font-weight: 700;
}

ul.dropdown-menu {
    background: rgba(21,21,21,1);
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
}
ul.dropdown-menu > li > a, .navbar ul ul ul.dropdown-menu li .nav-link {
    font-size: 12px;
    line-height: 22px;
    display: block;
    position: relative;
    z-index: 1;
    padding-left: 15px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #27243e;
    color: $white;
    text-transform: uppercase;

    &:hover{
        color: $primary-color;
    }
}

.dropdown-item:focus, .dropdown-item:hover {
    color:$white;
    text-decoration: none;
    background-color: transparent;

}

.dropdown-menu {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
    padding: 0;
    border: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    min-width: 210px;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform-origin: center top 0;
    -webkit-transform-origin: center top 0;
    -moz-transform-origin: center top 0;
    -ms-transform-origin: center top 0;
    -o-transform-origin: center top 0;
    transition: all .3s ease-in-out 0s;
    -webkit-transition: all .3s ease-in-out 0s;
    -moz-transition: all .3s ease-in-out 0s;
    -ms-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    -ms-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    -o-box-shadow: 0 6px 12px rgba(0,0,0,.175);

}

.dropdown:hover .dropdown-menu{
	opacity: 1;
	visibility:visible;
	-webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
}


@include mobile{
    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .banner .cd-headline {
        font-size: 32px;
        line-height: 42px;
    }
    .btn-group, .btn-group-vertical {
        display: block;
    }

    .btn-group > .btn {
        padding: 20px;
    }
}



@include mobile-xs{
    .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .banner .cd-headline {
        font-size: 32px;
        line-height: 42px;
    }
     .btn-group, .btn-group-vertical {
        display: block;
    }
    .btn-group > .btn {
        padding: 20px;
    }
}

@include tablet{
    .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .banner .cd-headline {
        font-size: 32px;
        line-height: 42px;
    }
     .btn-group, .btn-group-vertical {
        display: block;
    }
    .btn-group > .btn {
        padding: 20px;
    }
}
@include desktop{
    .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .banner .cd-headline {
        font-size: 32px;
        line-height: 42px;
    }
     .btn-group, .btn-group-vertical {
        display: block;
    }
    .btn-group > .btn {
        padding: 20px;
    }
}