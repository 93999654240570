.cd-headline.clip span{
	padding: 0px;
}

.banner .cd-headline {
    font-size: 56px;
    line-height: 70px;
}

.banner-3{
	position: relative;
}

